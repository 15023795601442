import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { NoData } from 'components/Errors/NoData';
import { BarCard } from './Cards';
import Grid from '@material-ui/core/Grid';
import styles from './styles.scss';
import { ReactTable } from 'components/ReactTable';


export const key = 'reports';

const MovementsTable = ({ movements, currency }) => {
	const columns = [
		{
			Header: 'Number',
			accessor: 'number',
			width: 100
		},
		{
			Header: 'Name',
			accessor: 'name',
			width: 200
		},
		{
			Header: 'Quantity',
			accessor: 'quantity',
			align: 'right',
			Cell: ({ value }) => value.toLocaleString()
		},
		{
			Header: 'Order Date',
			accessor: 'orderDate',
			align: 'center',
			Cell: ({ value }) => new Date(value).toLocaleDateString()
		},
		{
			Header: 'Pure Savings',
			id: 'pureSavings',
			accessor: row => row.savings.pure,
			align: 'right',
			Cell: ({ value }) => new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: currency
			}).format(value)
		},
		{
			Header: 'Inventory Savings',
			id: 'inventorySavings',
			accessor: row => row.savings.inventory,
			align: 'right',
			Cell: ({ value }) => new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: currency
			}).format(value)
		},
		{
			Header: 'Order Savings',
			id: 'orderSavings',
			accessor: row => row.savings.order,
			align: 'right',
			Cell: ({ value }) => new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: currency
			}).format(value)
		}
	];

	return (
		<div className="mt-8">
			<ReactTable
				columns={columns}
				data={movements}
				pageSize={10}
				pageText={<>{movements.length} Movements &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Page</>}
			/>
		</div>
	);
};


export function ValueTracker() {
	const { report } = useLoaderData();
	console.log('data in valuetracker', report);
	console.log('savings in report', report.savings);

	document.title = 'Value Tracker - GenLots';

	if (!report.savings) {
		return (
			<NoData
				title={'Value tracker'}
				message={'There are no results here as you probably don\'t have value tracking enabled.'}
			/>
		);
	}
	const formatValue = (value) => {
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: report.currency || 'USD',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		}).format(value);
	};

	// Create savings breakdown data for donut chart
	const savingsBreakdown = {
		'Inventory': report.savings.inventory,
		'Order': report.savings.order,
		'Quantity': report.savings.quantity,
		'Scrap': report.savings.scrap,
		'Total': report.savings.pure
	};


	return (
		<div className={styles.ReportsPage}>
			<div className={styles.Header2}>
				<div className={styles.Title}>Value tracker</div>
			</div>
			<div className={styles.Body}>
				<Grid container alignItems="flex-start" spacing={5}>
					<Grid item sm={12}>
						<BarCard
							title="Realized Savings"
							data={savingsBreakdown}
							containerId="savings-breakdown-chart"
							formatValue={formatValue}
							valueLabel=""
						/>
					</Grid>
					<Grid item sm={12}>
						<MovementsTable movements={report.movements} currency={report.currency} />
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

