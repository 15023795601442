import PropTypes from 'prop-types';
import React from 'react';
import { InventoryResultsChart } from 'containers/results/ResultsChart';
import { ChartFilter } from './ChartFilter';
import hoc from './hoc';
import { NoData } from 'components/Errors/NoData';

const ResultsSection = ({ data, options, filters, setFilter }) => {
	if (Object.keys(data).length < 3) return <NoData title={'No data found'} message={'This feature is not activated for you'}/>;
	return (
		<>
			<ChartFilter filters={filters} options={options} setFilter={setFilter} />
			<InventoryResultsChart data={data} filters={filters} options={options} />
		</>
	);
}

ResultsSection.propTypes = {
	data: PropTypes.object.isRequired,
	filters: PropTypes.object.isRequired,
	options: PropTypes.object.isRequired,
	setFilter: PropTypes.func
};

export default hoc(ResultsSection);
